<template>
  <v-card class="nospace">
    <v-dialog v-if="dialog2" max-width="400" v-model="documentData" persistent>
      <v-card>
        <v-card-title class="headline">Assign Executive</v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex class="pr-3" xs12 md12>
              {{ statusValue }}
              <span class="muted">Sales Executives*</span>
              <v-autocomplete
                :items="executives"
                item-text="name"
                item-value="_id"
                class="pt-0"
                v-model="selectedExecutive"
                single-line
              />
            </v-flex>
          </v-layout>
          <p class="grey--text" v-if="!executives.length">
            No Executives Available
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="darken-1 color-black"
            class="px-2 white--text"
            text
            @click="dialog2 = false"
            :disabled="loading"
            >Cancel</v-btn
          >
          <v-btn
            color="orange darken-1"
            class="px-2 white--text"
            text
            @click="assignExecutive()"
            :disabled="loading"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialog" max-width="400" v-model="documentData">
      <v-card>
        <v-card-title class="headline">Documents</v-card-title>
        <v-card-text>
          <a
            class="document"
            :href="documentData.passPort"
            v-if="documentData.passPort"
            target="_blank"
            >Passport</a
          >
          <br v-if="documentData.passPort" />
          <a
            class="document"
            :href="documentData.visa"
            target="_blank"
            v-if="documentData.visa"
            >Visa</a
          >
          <br v-if="documentData.vatCertificate" />
          <a
            class="document"
            :href="documentData.vatCertificate"
            v-if="documentData.vatCertificate"
            target="_blank"
            >Vat Certificate</a
          >
          <br v-if="documentData.emiratesId" />
          <a
            class="document"
            :href="documentData.emiratesId"
            v-if="documentData.emiratesId"
            target="_blank"
            >Emirates Id</a
          >
          <br v-if="documentData.tradeLicense" />
          <a
            class="document"
            :href="documentData.tradeLicense"
            v-if="documentData.tradeLicense"
            target="_blank"
            >Trade License</a
          >
          <p class="grey--text" v-else>No Document Available</p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="orange darken-1"
            class="px-2 white--text"
            text
            @click="dialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text class="nospace">
      <v-data-table
        v-model="selected"
        item-key="customerSerialNumber"
        class="elevation-1"
        id="customerListTable"
        v-if="tabFilter == 'true'"
        hide-actions
        style="word-break: break-all"
        :headers="headersVerified"
        :items="items"
      >
        <template slot="items" slot-scope="props">
          <td>
            <v-layout row>
              <v-flex>
                <v-checkbox
                  v-if="checkCondition && props.item.workingCountry != 'PAK'"
                  class="align-center justify-center"
                  v-model="props.item.selected"
                  @change="
                    checkSelected(
                      props.item.selected,
                      props.item.customerSerialNumber,
                      $event
                    )
                  "
                  primary
                  hide-details
                >
                </v-checkbox>
              </v-flex>
              <!-- <ActivityLogs
              v-permissions="'view-logs-customer'"
              :customerId="props.item.customerSerialNumber"
              :user="props.item._id"
            /> -->
              <v-flex>
                <activityLogs
                  :type="'customer'"
                  :permissions="'view-logs-customer'"
                  :_id="props.item._id"
                  :id="props.item.customerSerialNumber.toString()"
                />
              </v-flex>
              <v-flex pt-1>
                <div :class="[props.item.isDocumentsExpiry ? 'text-red' : '']">
                  {{ props.item.customerSerialNumber }}
                </div>
              </v-flex>
            </v-layout>
            <div v-if="props.item.isBlocked">
              <span class="red-text2 pl-3">(Blocked)</span>
            </div>
          </td>
          <td>
            <img
              v-if="props.item.workingCountry == 'PAK'"
              src="../../assets/pakflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'KSA'"
              src="../../assets/ksaflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'UAE'"
              src="../../assets/Uaeflag.png"
              height="20px"
            />
            <img
              v-if="props.item.workingCountry == 'UAE LA'"
              src="../../assets/Uaeflag.png"
              height="20px"
              style="border-radius: 50%"
            />
            <br />{{ props.item.workingCountry || "-" }}
          </td>
          <td>
            {{ props.item.companyName || "N.A" }}
            <br />
            <CustomerDetailPopUp :detail="props.item" />
          </td>
          <td>{{ props.item.createdOn }}</td>
          <td>
            {{ props.item.deviceType }}
            <br />
            {{ props.item.signupType || "N.A" }}
          </td>
          <td>
            <span
              v-if="
                props.item.saleExecutive &&
                props.item.saleExecutive.isBlocked == false
              "
            >
              {{
                props.item.saleExecutive
                  ? props.item.saleExecutive.name +
                    " (" +
                    props.item.saleExecutive.email +
                    ")"
                  : "N.A."
              }}
              <br />
              {{
                props.item.saleExecutive
                  ? props.item.saleExecutive.countryCode +
                    "-" +
                    props.item.saleExecutive.phoneNo
                  : ""
              }}</span
            >
            <span v-else>
              {{
                props.item.saleExecutive ? "Sales Executive - " + "N.A" : ""
              }}</span
            >
            <br />
            <button
              v-permissions="'assign-executive'"
              class="track-ass-btn"
              @click="checkAssign(props.index)"
              :disabled="loading"
            >
              Assign Executive
            </button>
          </td>
          <!-- <td>
            {{ props.item.creditLimit }} {{ currency }} |
            {{ props.item.creditUsed }} {{ currency }}
          </td> -->

          <!-- <td>
            <button class="view-ass-btn" @click="openDocument(props.item)">
              View
            </button>
          </td> -->

          <td>
            <div class="text-center">
              {{ props.item.totalBookings || "0" }} |
              {{ props.item.totalInquiry || "0" }}
            </div>
            <button
              @click="goToDetailPage(props.item)"
              target="_blank"
              class="view-ass-btn m-t-10 m-l-10"
            >
              View
            </button>
          </td>
          <td
            v-if="
              props.item.ibAccess == true && props.item.workingCountry != 'PAK'
            "
          >
            True
          </td>
          <td
            v-else-if="
              props.item.ibAccess == false && props.item.workingCountry != 'PAK'
            "
          >
            False
          </td>
          <td v-else>N.A.</td>

          <td style="width: 10%" v-if="props.item.accountVerified == true">
            Verified
          </td>
          <td v-else>Not Verified</td>
          <td style="width: 20%">
            <emailNotification :detail="props.item" />
            <button
              @click="goToDetailPage1(props.item)"
              target="_blank"
              class="track-ass-btn green-button m-t-10 m-l-10"
            >
              Details
            </button>

            <!-- <button 
                                v-permissions="'edit-customer'"
                                @click="checkEdit(props.item.userId)"
                                class="track-ass-btn green-button"
                                title="Edit Customer"
                            >Edit</button>  -->

            <button
              @click="blockCustomer(props.item._id, 'false')"
              v-if="props.item.isBlocked"
              v-permissions="'block-customer'"
              class="track-ass-btn ml-1 yellow-button"
              title="Unblock Customer"
            >
              Unblock
            </button>

            <button
              @click="blockCustomer(props.item._id, 'true')"
              v-if="!props.item.isBlocked"
              v-permissions="'block-customer'"
              class="track-ass-btn ml-1 red-button"
              title="Block Customer"
            >
              Block
            </button>

            <v-select
              v-if="props.item.isLead"
              label="Lead's Status"
              :items="leadStatus"
              class="pt-0"
              style="padding-top: 12px !important"
              v-model="props.item.status"
              @input="(status) => changeLeadStatus(props.index)"
              single-line
            />
            <v-tooltip
              top
              class="editCustomer"
              content-class="tooltip-op"
              color="green darken-1"
            >
              <template slot="activator">
                <v-icon
                  color="green darken-1"
                  flat
                  prepend-icon
                  v-if="props.item.showPrice"
                  @click.native="showPrice()"
                  class="customer-actions"
                  title="Show Price"
                  >done</v-icon
                >
              </template>
              <span class="tooltip">Show Price</span>
            </v-tooltip>
          </td>
        </template>
        <v-alert
          slot="no-results"
          :value="isNoSearchResult"
          color="blue"
          icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
      <v-data-table
        id="customerListTable"
        hide-actions
        v-if="tabFilter == 'false'"
        style="word-break: break-all"
        :headers="headers"
        :items="items"
      >
        <template slot="items" slot-scope="props">
          <td>
            <activityLogs
              :type="'customer'"
              :permissions="'view-logs-customer'"
              :_id="props.item._id"
              :id="props.item.customerSerialNumber.toString()"
            />
            <div :class="[props.item.isDocumentsExpiry ? 'text-red' : '']">
              {{ props.item.customerSerialNumber }}
            </div>
            <!-- <ActivityLogs
              v-permissions="'view-logs-customer'"
              :customerId="props.item.customerSerialNumber"
              :user="props.item._id"
            /> -->

            <div v-if="props.item.isBlocked">
              <span class="red-text2">(Blocked)</span>
            </div>
          </td>
          <td>
            <img
              v-if="props.item.workingCountry == 'PAK'"
              src="../../assets/pakflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'KSA'"
              src="../../assets/ksaflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'UAE'"
              src="../../assets/Uaeflag.png"
              height="20px"
            /><br />{{ props.item.workingCountry || "-" }}
          </td>
          <td>
            {{ props.item.companyName || "N.A" }}
            <br />
            <CustomerDetailPopUp :detail="props.item" />
          </td>
          <td>{{ props.item.createdOn }}</td>
          <td>
            {{ props.item.deviceType }}
            <br />
            {{ props.item.signupType || "N.A" }}
          </td>
          <td>
            <span
              v-if="
                props.item.saleExecutive &&
                props.item.saleExecutive.isBlocked == false
              "
            >
              {{
                props.item.saleExecutive
                  ? props.item.saleExecutive.name +
                    " (" +
                    props.item.saleExecutive.email +
                    ")"
                  : "N.A."
              }}
              <br />
              {{
                props.item.saleExecutive
                  ? props.item.saleExecutive.countryCode +
                    "-" +
                    props.item.saleExecutive.phoneNo
                  : ""
              }}</span
            >
            <span v-else style="word-break: break-word">
              {{ props.item.saleExecutive ? "Sales Executive- N.A" : "" }}</span
            >
            <br />
            <button
              v-permissions="'assign-executive'"
              class="track-ass-btn"
              @click="checkAssign(props.index)"
              :disabled="loading"
            >
              Assign Executive
            </button>
          </td>
          <!-- <td>
            {{ props.item.creditLimit }} {{ currency }} |
            {{ props.item.creditUsed }} {{ currency }}
          </td> -->

          <!-- <td>
            <button class="view-ass-btn" @click="openDocument(props.item)">
              View
            </button>
          </td> -->

          <td>
            <div class="text-center">
              {{ props.item.totalBookings || "0" }} |
              {{ props.item.totalInquiry || "0" }}
            </div>
            <button
              @click="goToDetailPage(props.item)"
              target="_blank"
              class="view-ass-btn m-t-10 m-l-10"
            >
              View
            </button>
          </td>
          <td style="width: 10%" v-if="props.item.accountVerified == true">
            Verified
          </td>
          <td v-else>Not Verified</td>
          <td>
            {{
              props.item.isVerifiedByAccounts != "NotVerified"
                ? props.item.isVerifiedByAccounts
                : ""
            }}
            <confirmationPopup
              v-if="props.item.isVerifiedByAccounts === 'NotVerified'"
              v-permissions="'approve-customer'"
              :message="'Are you sure want to approve customer?'"
              :confirmationTitle="'Approve Customer'"
              :cancelBtn="'Cancel'"
              :confirmBtn="'Approve'"
              :customerData="props.item"
              :type="'customer'"
              @refresh-list="refreshList()"
            />
            <CancellationPopup
              v-if="props.item.isVerifiedByAccounts === 'NotVerified'"
              v-permissions="'reject-customer'"
              :message="'Are you sure want to reject?'"
              :confirmationTitle="'Reject Customer'"
              :cancelBtn="'Cancel'"
              :confirmBtn="'Reject'"
              :customerData="props.item"
              @refresh-list="refreshList()"
            />
          </td>
          <td style="width: 20%">
            <emailNotification :detail="props.item" />
            <button
              @click="goToDetailPage1(props.item)"
              target="_blank"
              class="track-ass-btn green-button"
            >
              Details
            </button>

            <!-- <button 
                                v-permissions="'edit-customer'"
                                @click="checkEdit(props.item.userId)"
                                class="track-ass-btn green-button"
                                title="Edit Customer"
                            >Edit</button>  -->

            <button
              @click="blockCustomer(props.item._id, 'false')"
              v-if="props.item.isBlocked"
              v-permissions="'block-customer'"
              class="track-ass-btn m-t-10 m-l-10 yellow-button"
              title="Unblock Customer"
              :disabled="loading"
            >
              Unblock
            </button>

            <button
              @click="blockCustomer(props.item._id, 'true')"
              v-if="!props.item.isBlocked"
              v-permissions="'block-customer'"
              class="track-ass-btn m-t-10 m-l-10 red-button"
              title="Block Customer"
              :disabled="loading"
            >
              Block
            </button>

            <v-select
              v-if="props.item.isLead"
              label="Lead's Status"
              :items="leadStatus"
              class="pt-0"
              style="padding-top: 12px !important"
              v-model="props.item.status"
              @input="(status) => changeLeadStatus(props.index)"
              single-line
            />
            <v-tooltip
              top
              class="editCustomer"
              content-class="tooltip-op"
              color="green darken-1"
            >
              <template slot="activator">
                <v-icon
                  color="green darken-1"
                  flat
                  prepend-icon
                  v-if="props.item.showPrice"
                  @click.native="showPrice()"
                  class="customer-actions"
                  title="Show Price"
                  >done</v-icon
                >
              </template>
              <span class="tooltip">Show Price</span>
            </v-tooltip>
          </td>
        </template>
        <v-alert
          slot="no-results"
          :value="isNoSearchResult"
          color="blue"
          icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
      <pagination
        v-if="this.workingCountry"
        :totalPages="totalPages"
        :pages="this.pages"
        v-on:pagination-load="checkLoading"
        @pageNo="page"
        :url="`/dmsAdmin/custList`"
        :callback="callback"
        :componentKey="componentKey"
        :action="`customer`"
        :payload="payload"
        :type="1"
      />
    </v-card-text>
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </v-card>
</template>

<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import { ApiService } from "@/services/api-service";
import moment from "moment";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import {
  customerList,
  customerListVerified,
} from "@/constants/datatable-headers.js";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import activityLogs from "../Common/activityLogs";
import pagination from "@/components/Pagination/pagination";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import { debuglog } from "util";
import { StorageKeys } from "../../constants/constants";
import confirmationPopup from "@/components/Pop-ups/confirmationPopup.vue";
import CancellationPopup from "@/components/Pop-ups/CancellationPopup.vue";
import CustomerDetailPopUp from "./CustomerDetailPopUp";
import emailNotification from "./emailNotification";
import { editCustomer } from "../../constants/api-urls";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
          this.currency = key.currency;
        }
      });
      this.workingCountry = workingCountry;
      this.payload = {
        isApproved: this.tabFilter,
        workingCountry: this.workingCountry,
      };
    }
    this.$eventBus.$on("customers", (data) => {
      this.componentKey = this.componentKey + 1;
    });
    this.$eventBus.$on("Ibupdate", (data) => {
      this.componentKey = this.componentKey + 1;
    });
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "customer-section") {
        this.workingCountry = val;
        this.payload.workingCountry = this.workingCountry;
        // this.startDate = "";
        // this.endDate = "";
        // this.payload = {
        //   isApproved: this.$props.tabFilter,
        //   workingCountry: this.workingCountry,
        // };

        this.$eventBus.$emit("on-load");
      }
    });

    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
    // this.fetchExecutives();
  },
  components: {
    pagination,
    activityLogs,
    confirmationPopup,
    CancellationPopup,
    SuccessDialog,
    ErrorBox,
    CustomerDetailPopUp,
    emailNotification,
  },
  props: {
    loader: Boolean,
    dashboardData: Function,
    searchText: String,
    countryFilter: String,
    reset: Boolean,
    tabFilter: String,
    TypeFilter: String,
    Type: String,
    dateRangenew: Array,
    isAccountVerified: Boolean,
    isAssigned: String,
    executiveFilter: String,
    //dateRange: Object,
    sortBy: String,
    checkBoxShow: Boolean,
  },
  watch: {
    dateRangenew: function (n, o) {
      if (!n) {
        this.$eventBus.$emit("on-load");
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
      if (n) {
        this.$eventBus.$emit("on-load");
        this.loading = false;
        if (n.length && n[0] && n[1]) {
          this.startDate = moment(n[0]).format("YYYY-MM-DD");
          this.endDate = moment(n[1]).format("YYYY-MM-DD");
        }

        // if (n != "") {
        //   if (n.startDate && n.endDate) {
        //     this.startDate = moment(this.dateRange.startDate).format(
        //       "YYYY-MM-DD"
        //     );
        //     this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
        //   }
        this.$set(this.payload, "regDateFrom", null);
        this.$set(this.payload, "regDateFrom", this.startDate);

        this.payload.regDateTo = this.endDate;
      } else {
        this.payload = {
          isApproved: this.tabFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
    sortBy: function (n, o) {
      if (n != "") {
        this.sortValue = n;
        if (n == "booking desc") {
          this.$set(this.payload, "sortBy", "booking");
          this.$set(this.payload, "sortType", "dssc");
          this.payload.sortType = "desc";
        } else if (n == "booking asc") {
          this.$set(this.payload, "sortBy", "booking");
          this.$set(this.payload, "sortType", "asc");
          this.payload.sortType = "asc";
        } else if (n == "inquiry asc") {
          this.$set(this.payload, "sortBy", "inquiry");
          this.$set(this.payload, "sortType", "asc");
        } else if (n == "inquiry desc") {
          this.$set(this.payload, "sortBy", "inquiry");
          this.$set(this.payload, "sortType", "desc");
          this.payload.sortType = "desc";
        } else if (n == "company asc") {
          this.$set(this.payload, "sortBy", "companyName");
          this.$set(this.payload, "sortType", "asc");
          this.payload.sortType = "asc";
        } else if (n == "company desc") {
          this.$set(this.payload, "sortBy", "companyName");
          this.$set(this.payload, "sortType", "desc");
          this.payload.sortType = "desc";
        }

        ///  this.$set(this.payload, "isAccountVerified", n);
      } else {
        this.payload = {
          isApproved: this.tabFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
    checkBoxShow: function (n, o) {
      this.checkCondition = n;
    },
    executiveFilter: function (n, o) {
      this.executiveId = n;
      // this.executiveValue = n._id;
      if (n != "") {
        this.$set(this.payload, "salesExecutive", n);
      }
    },

    isAccountVerified: function (n, o) {
      this.statusValue = n;
      this.$set(this.payload, "status", n);
    },
    // isApproved: function(n, o) {
    //   this.statusApprovedValue = n;
    //   this.$set(this.payload, "isApproved", n);
    //   this.payload.skip = "0";
    //   this.payload.limit = "15";
    // },
    isAssigned: function (n, o) {
      this.executiveValue = n;
      if (n != "") {
        this.$set(this.payload, "isAssigned", n);
      } else {
        this.payload = {
          isApproved: this.tabFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
    // dateRange: function (n, o) {
    //   if (n != "") {
    //     if (n.startDate && n.endDate) {
    //       this.startDate = moment(this.dateRange.startDate).format(
    //         "YYYY-MM-DD"
    //       );
    //       this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
    //     }
    //     this.$set(this.payload, "regDateFrom", this.startDate);
    //     this.payload.skip = "0";
    //     this.payload.limit = "15";
    //     this.payload.regDateTo = this.endDate;
    //   } else {
    //     this.payload = {
    //       skip: "0",
    //       limit: "15",
    //       status: this.tabFilter,
    //       workingCountry: this.workingCountry,
    //     };
    //   }
    // },
    countryFilter: function (n, o) {
      if (n != "") {
        this.$set(this.payload, "country", n);
      } else {
        this.payload = {
          isApproved: this.tabFilter,
          workingCountry: this.workingCountry,
        };
      }
    },

    searchText: function (n, o) {
      if (n.trim() != "") {
        this.payload.searchType = this.Type;
        this.$eventBus.$emit("on-load");
        this.$set(this.payload, "searchText", n);

        if (this.statusValue) {
          this.$set(this.payload, "isAccountVerified", this.statusValue);
        }
        if (this.sortValue) {
          if (this.sortValue == "booking desc") {
            this.$set(this.payload, "sortBy", "booking");
            this.$set(this.payload, "sortType", "dssc");
            this.payload.sortType = "desc";
          } else if (this.sortValue == "booking asc") {
            this.$set(this.payload, "sortBy", "booking");
            this.$set(this.payload, "sortType", "asc");
            this.payload.sortType = "asc";
          } else if (this.sortValue == "inquiry asc") {
            this.$set(this.payload, "sortBy", "inquiry");
            this.$set(this.payload, "sortType", "asc");
          } else if (this.sortValue == "inquiry desc") {
            this.$set(this.payload, "sortBy", "inquiry");
            this.$set(this.payload, "sortType", "desc");
            this.payload.sortType = "desc";
          }
        }
        if (this.executiveValue) {
          this.$set(this.payload, "isAssigned", this.executiveValue);
        }
        if (this.executiveId) {
          this.$set(this.payload, "salesExecutive", this.executiveId);
        }

        if (this.startDate && this.endDate) {
          this.$set(this.payload, "regDateFrom", this.startDate);
          this.payload.regDateTo = this.endDate;
        }
      } else {
        this.payload = {
          isApproved: this.tabFilter,
          workingCountry: this.workingCountry,
        };

        if (this.sortValue) {
          if (this.sortValue == "booking desc") {
            this.$set(this.payload, "sortBy", "booking");
            this.$set(this.payload, "sortType", "dssc");
            this.payload.sortType = "desc";
          } else if (this.sortValue == "booking asc") {
            this.$set(this.payload, "sortBy", "booking");
            this.$set(this.payload, "sortType", "asc");
            this.payload.sortType = "asc";
          } else if (this.sortValue == "inquiry asc") {
            this.$set(this.payload, "sortBy", "inquiry");
            this.$set(this.payload, "sortType", "asc");
          } else if (this.sortValue == "inquiry desc") {
            this.$set(this.payload, "sortBy", "inquiry");
            this.$set(this.payload, "sortType", "desc");
            this.payload.sortType = "desc";
          }
        }
        if (this.statusValue) {
          this.$set(this.payload, "isAccountVerified", this.statusValue);
        }
        if (this.executiveId) {
          this.$set(this.payload, "salesExecutive", this.executiveId);
        }
        if (this.executiveValue) {
          this.$set(this.payload, "isAssigned", this.executiveValue);
        }
        if (this.startDate && this.endDate) {
          this.$set(this.payload, "regDateFrom", this.startDate);
          this.payload.regDateTo = this.endDate;
        }

        if (this.searchTextValue) {
          this.$set(this.payload, "searchText", this.searchTextValue);
          this.payload.searchType = this.Type;
        }
      }
    },
    tabFilter: function (n, o) {
      this.$eventBus.$emit("on-load");
      this.$set(this.payload, "isApproved", n);
    },
    reset: function (n, o) {
      if (n == true) {
        this.startDate = "";
        (this.statusValue = null),
          (this.endDate = ""),
          (this.sortValue = ""),
          (this.selectedids = []),
          (this.selectednames = []),
          (this.statusApprovedValue = null),
          (this.executiveValue = ""),
          (this.executiveId = ""),
          (this.payload = {
            isApproved: this.tabFilter,
            workingCountry: this.workingCountry,
          });
      }
    },
    deep: true,
    immediate: true,
  },
  data() {
    return {
      workingCountry: null,
      selected: [],
      selectedids: [],
      selectednames: [],
      startDate: "",
      checkCondition: false,
      executiveValue: "",
      sortValue: "",
      executiveId: "",
      statusValue: null,
      statusApprovedValue: null,

      endDate: "",
      dialog: false,
      currency: "",
      dialog2: false,
      processing: false,
      componentKey: 0,
      search: "",
      isNoSearchResult: false,
      loading: true,
      payload: {},
      pages: 1,
      totalPages: 0,
      x: {
        message: "",
        loading: false,
        success: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },
      leadStatus: [
        { value: "Pending", text: "Pending(Lead's Status)" },
        { value: "Approached", text: "Approached" },
        { value: "Proposal", text: "Proposal" },
        { value: "Quotation", text: "Quotation" },
        { value: "Won", text: "Won" },
        { value: "Lost", text: "Lost" },
      ],
      headers: customerList,
      headersVerified: customerListVerified,
      documentData: [],
      items: [],
      executives: [],
      selectedExecutive: null,
      currentIndexForCustomer: null,
    };
  },
  methods: {
    checkLoading(event) {
      if (event) {
        this.$emit("on-load");
      } else {
        // this.$emit("job-load", false);
        this.$emit("off-load");
      }
    },

    checkSelected(select, id, e) {
      if (select && !this.selectedids.includes(id)) {
        this.selectedids.push(id);
        this.selectednames.push(id);
      }
      if (!select && this.selectedids.includes(id)) {
        this.selectedids.splice(this.selectedids.indexOf(id), 1);
        this.selectednames.splice(this.selectednames.indexOf(id), 1);
      }
      this.$emit("selectIb", this.selectedids);
    },

    refreshList() {
      this.componentKey = this.componentKey + 1;
    },
    async checkEdit(id) {
      this.operationName = "edit-customer";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.editCustomer(id);
      } else {
        return;
      }
    },
    async checkDetail(id) {
      this.operationName = "view-details-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.goToDetailPage(id);
      } else {
        return;
      }
    },
    async checkDetail1(id) {
      this.operationName = "view-details-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.goToDetailPage1(id);
      } else {
        return;
      }
    },
    async checkAssign(id) {
      this.operationName = "assign-executive";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.openExecutivePop(id);
        this.fetchExecutives();
      } else {
        return;
      }
    },
    page(event) {
      this.pages = event;
    },

    changeLeadStatus(index) {
      let url = "/dmsAdmin/editCustomer";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        // custId: id.toString(),
        userId: this.items[index]._id.toString(),
        comments: "Updating customers status to " + this.items[index].status,
        status: this.items[index].status,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.x.success = true;
        },
        (error) => {
          this.x.error = error.response.data.message;
        }
      );
    },
    assignExecutive() {
      if (!this.selectedExecutive) {
        this.x.error = "Please select any executive";
        return;
      }
      let url = "/sales/assignCust";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        // custId: id.toString(),
        executiveId: this.selectedExecutive,
        custId: this.items[this.currentIndexForCustomer]._id,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          //this.items[this.currentIndexForCustomer].saleExecutive =
          //response.data.data.saleExecutive;

          this.dialog2 = false;
          this.componentKey++;
        },
        (error) => {
          this.x.error = error.response.data.message;
        }
      );
    },
    openExecutivePop(index) {
      this.selectedExecutive = null;

      this.currentIndexForCustomer = index;
      this.dialog2 = true;
    },
    fetchExecutives() {
      ApiService.getData(
        `/sales/executiveList?workingCountry=${JSON.stringify(
          this.workingCountry
        )}`
      ).then(
        (response) => {
          this.executives = response.data.data;
        },
        (error) => {}
      );
    },
    paginationPayload(event) {
      this.payload = event;
      localStorage.removeItem("current-customer-page-status");
    },
    openDocument(data) {
      this.getCustomer(data.userId);
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    getCustomer(id) {
      this.loading = true;
      let url = "/dmsAdmin/custDetails";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: id.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.documentData = response.data.data;
          this.dialog = true;
        },
        (error) => {
          this.x.error = "Failed to Fetch User Details";
        }
      );
    },
    editCustomer(id) {
      localStorage.setItem(
        "current-customer-page-status",
        JSON.stringify(this.payload)
      );
      this.$router.push(`/customer/edit/${id}`);
    },
    showPrice() {},
    async blockCustomer(id, status) {
      this.operationName = "block-customer";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (this.processing) {
          return;
        } else {
          this.processing = true;
          let blockUrl = "/dmsAdmin/editCustomer";
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          let comments = "";
          if (status == "true") {
            comments = "Block By Admin";
          } else {
            comments = "Unblocked By Admin.";
          }
          let body = {
            userId: id.toString(),
            comments,
            isBlocked: status,
          };
          this.axios.post(this.constants.apiUrl + blockUrl, body, config).then(
            (response) => {
              this.payload = {
                isApproved: this.tabFilter,
                workingCountry: this.workingCountry,
              };
              this.$emit("on-block");

              this.x.success = true;
              this.processing = false;
              this.x.message = response.data.message;
            },
            (error) => {
              this.processing = false;
              this.x.error = error.response.data.message;
            }
          );
        }
      } else {
        return;
      }
    },
    getTime(date) {
      return moment(date).format("ll");
    },

    goToDetailPage(customer) {
      this.$eventBus.$emit("customer-details-count", customer);
      localStorage.removeItem("changeBasicTabListing");
      this.$router.push(`/customer/details/${customer.userId}/${customer._id}`);
    },
    goToDetailPage1(customer) {
      this.$eventBus.$emit("customer-details-count", customer);
      localStorage.setItem("changeBasicTabListing", "basicDetail");
      this.$router.push(`/customer/details/${customer.userId}/${customer._id}`);
    },
    callback(res) {
      //localStorage.setItem("payloadCustomer",JSON.stringify(this.payload));
      this.loading = false;
      this.items = res.list;
      this.items.forEach((e) => {
        e.selected = false;
        if (this.selectedids.includes(e.customerSerialNumber)) {
          e.selected = true;
        }
      });
      this.totalPages = Math.ceil(res.totalData / 15);
      for (var i = 0; i < this.items.length; i++) {
        this.items[i].registrationDate
          ? (this.items[i].createdOn = moment(
              String(this.items[i].registrationDate)
            ).format("ll LT"))
          : "N.A";
      }
      this.dashboardData(res);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
#customerListTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#customerListTable
  .theme--light.v-datatable
  thead
  th.column.sortable.active
  .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.v-card {
  padding: 15px;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.text-red {
  color: red;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.red-text2 {
  color: red;
  animation: animate 1.5s linear infinite;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
</style>
